<template>
  <div id="createProfiles">
    <div class="banner">
      <div class="banner-bg" :style="bannerBg"></div>
      <div class="banner-bg-shadow"></div>
      <div class="banner-tit">{{ $t('CreateProfiles.tit') }}</div>
      <el-button
        type="text"
        class="banner-btn"
        @click="
          dialogFormVisible5 = true
          picNum = 1
        "
        >{{ $t('CreateProfiles.bgBtn') }}</el-button
      >
      <el-dialog :title="$t('CreateProfiles.editinfo')" :visible.sync="dialogFormVisible5" :width="screenWidth > 480 ? '50%' : '98%'">
        <el-form>
          <el-form-item>
            <el-upload
              ref="upload1"
              action="#"
              list-type="picture-card"
              :limit="1"
              :multiple="false"
              :on-remove="handleRemove1"
              :on-change="handleChange1"
              :auto-upload="false"
              :before-upload="beforeUpload1"
              :http-request="uploadPhotos1"
              :file-list="fileList1"
            >
              <i v-if="fileList1.length < 1" slot="default" class="el-icon-plus" />
            </el-upload>
            <p>{{ $t('CreateProfiles.pre_made_banner') }}</p>

            <el-radio-group v-model="bannerRadio" @change="changeBannerImg" size="mini">
              <template>
                <el-row :gutter="20">
                  <el-col :span="6" class="image-radios" v-for="(image, index) in imagebanners" :key="index">
                    <el-radio-button :label="image.filename"><img class="banner-choose" :src="image.url" /></el-radio-button>
                  </el-col>
                </el-row>
              </template>
            </el-radio-group>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible5 = false">{{ $t('CreateProfiles.cancel') }}</el-button>
          <el-button type="primary" :loading="BtnLoading" @click="isFileChange ? uploadPic1() : save('dialogFormVisible5')">{{ $t('CreateProfiles.save') }}</el-button>
        </div>
      </el-dialog>
    </div>
    <div class="center-background">
      <div class="center-container" style="max-width: 1200px; margin: 0 auto; width: 90%">
        <div class="info">
          <div class="card card1">
            <div class="card1-left">
              <div class="card1-info">
                <div class="head-portrait" :style="headBg" />
                <div class="person-info">
                  <div class="card1-name">
                    <span class="person-name">
                      {{ info_data.name }}
                    </span>
                    <span class="icon-group">
                      <span>
                        <img v-if="inspire_learn_record >= 7" class="icon" src="../assets/img/2-Icons/icons_profile-medal.svg" alt="" />
                        <img v-if="safeguarding_learn_record >= 5" class="icon" src="../assets/img/2-Icons/icons_safe_0408.png" alt="" />
                        <img v-if="event_record >= 3" class="icon" src="../assets/img/2-Icons/icons_profile-trophy.svg" alt="" />
                      </span>
                    </span>
                  </div>
                  <div class="card1-tit">{{ info_data.title }}</div>
                  <div class="card1-left-line"></div>
                  <div class="main-sport">
                    <span class="txt2">{{ $t('CreateProfiles.mainsport') }}</span>
                    <span v-if="info_data.main_sport">
                      <span class="txt3 mainsport" v-for="(i, o) in info_data.main_sport" :key="o">
                        {{ i && sportType.find((one) => one.name_en == i) && sportType.find((one) => one.name_en == i).name }}
                        <span v-if="o != info_data.main_sport.length - 1">,</span>
                      </span>
                    </span>
                  </div>
                  <div>
                    <span class="txt2">
                      {{ $t('CreateProfiles.coachtarget') }}
                    </span>
                    <span class="txt3" v-for="(i, o) in info_data.coach_target" :key="o">
                      {{ i && coachingTarget.find((one) => one.value == i) && coachingTarget.find((one) => one.value == i).label }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="card1-right">
              <div class="card1-line"></div>
              <div class="button-wrapper">
                <div class="info-btn">
                  <button class="save-btn" @click="saveCoach()" :disabled="!coachId">
                    {{ isSaved ? $t('CoachProfiles.btn5') : $t('CoachProfiles.btn3') }}
                  </button>
                </div>
                <div class="info-btn">
                  <button class="now-btn" @click="handleConnect()" :disabled="!coachId">
                    {{ $t('CreateProfiles.btn4') }}
                  </button>
                </div>
              </div>
            </div>
            <el-dialog :title="$t('CoachProfiles.select_job')" :visible.sync="dialogConnect">
              <el-form>
                <el-form-item :label="$t('CoachProfiles.job_name')" label-width="200px">
                  <el-select v-model="jobId" :placeholder="$t('CoachProfiles.select_job')" style="width: 300px">
                    <el-option v-for="(item, index) in myUnconfirmedJobs" :key="index" :label="item.index + ' ' + item.event_name" :value="item.id"></el-option>
                  </el-select>
                </el-form-item>
              </el-form>
              <div slot="footer" class="dialog-footer">
                <el-button @click="dialogConnect = false">{{ $t('CoachProfiles.cancel') }}</el-button>
                <el-button type="primary" @click="confirmJob()">{{ $t('CoachProfiles.confirm') }} </el-button>
              </div>
            </el-dialog>
            <el-button type="text" class="edit-btn" icon="el-icon-edit" @click="dialogFormVisible1 = true"> {{ $t('CreateProfiles.edit') }}</el-button>
            <el-dialog :width="screenWidth > 480 ? '50%' : '98%'" :title="$t('CreateProfiles.editinfo')" :visible.sync="dialogFormVisible1">
              <el-form :model="form1" ref="form1" :rules="rules1">
                <el-form-item :label="$t('CreateProfiles.name') + '(CN)'" :label-width="formLabelWidth" prop="name_zh_tw">
                  <el-input v-model="form1.name_zh_tw" autocomplete="off" :prop="form1.name_zh_tw"> </el-input>
                </el-form-item>
                <el-form-item :label="$t('CreateProfiles.name') + '(EN)'" :label-width="formLabelWidth" prop="name_en">
                  <el-input v-model="form1.name_en" autocomplete="off" :prop="form1.name_en"> </el-input>
                </el-form-item>
                <el-form-item :label="$t('CreateProfiles.mainsport')" :label-width="formLabelWidth" prop="main_sport">
                  <el-select :placeholder="$t('CreateProfiles.pleaseselect')" v-model="form1.main_sport" style="width: 100%" multiple>
                    <el-option v-for="item in sportType" :key="item.name_en" :label="item.name" :value="item.name_en"> </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item :label="$t('CreateProfiles.coachtarget')" :label-width="formLabelWidth" prop="coach_target">
                  <el-select :placeholder="$t('CreateProfiles.pleaseselect')" v-model="form1.coach_target" style="width: 100%" multiple>
                    <el-option v-for="item in coachingTarget" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item :label="$t('CreateProfiles.portrait')" :label-width="formLabelWidth">
                  <el-upload
                    ref="upload2"
                    action="#"
                    list-type="picture-card"
                    :limit="1"
                    :multiple="false"
                    :on-remove="handleRemove2"
                    :on-change="handleChange2"
                    :auto-upload="false"
                    :before-upload="beforeUpload2"
                    :http-request="uploadPhotos2"
                    :file-list="fileList2"
                  >
                    <i v-if="fileList2.length < 1" slot="default" class="el-icon-plus" />
                  </el-upload>
                  <p>{{ $t('CreateProfiles.pre_made_banner') }}</p>

                  <el-radio-group v-model="avatarRadio" @change="changeAvatarImg" size="mini">
                    <template>
                      <el-row :gutter="20">
                        <el-col :span="6" class="image-radios" v-for="(image, index) in avatarimages" :key="index">
                          <el-radio-button :label="image.filename"><img class="banner-choose" :src="image.url" /></el-radio-button>
                        </el-col>
                      </el-row>
                    </template>
                  </el-radio-group>
                </el-form-item>
              </el-form>
              <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible1 = false">{{ $t('CreateProfiles.cancel') }}</el-button>
                <el-button type="primary" :loading="BtnLoading" @click="isFileChange2 ? uploadPic2() : save('dialogFormVisible1', 'form1')">{{ $t('CreateProfiles.save') }}</el-button>
              </div>
            </el-dialog>
          </div>
          <div class="card-group">
            <div class="card card-left">
              <div class="card-info">
                <div class="box">
                  <div class="txt1">
                    {{ $t('CreateProfiles.infoTit2') }}
                  </div>
                  <div class="txt3" style="white-space: pre-wrap">
                    {{
                      $i18n.locale == 'en' ? (info_data.about_me_en ? info_data.about_me_en : info_data.about_me_zh_tw) : info_data.about_me_zh_tw ? info_data.about_me_zh_tw : info_data.about_me_en
                    }}
                  </div>
                </div>
                <div class="box">
                  <div class="txt1">
                    {{ $t('CreateProfiles.infoTit3') }}
                  </div>
                  <div class="txt3" v-if="info_data.is_public && $i18n.locale == 'en'">
                    {{ info_data.coaching_fee == null ? info_data.coaching_fee : `$${info_data.coaching_fee} ` + $t('CreateProfiles.Perhour') }}
                  </div>
                  <div class="txt3" v-if="info_data.is_public && $i18n.locale == 'cn'">
                    {{ info_data.coaching_fee == null ? info_data.coaching_fee : $t('CreateProfiles.Perhour') + `$${info_data.coaching_fee} ` }}
                  </div>
                  <div class="txt3" v-if="!info_data.is_public">
                    {{ $t('CreateProfiles.unpublic') }}
                  </div>
                </div>
                <div class="box">
                  <div class="txt1">
                    {{ $t('CreateProfiles.infoTit4') }}
                  </div>
                  <div class="info-box">
                    <div class="card-left-info" v-for="(i, o) in info_data.QualificationCertificates" :key="o">
                      <div class="txt2">
                        {{
                          $i18n.locale == 'en'
                            ? i.name_of_issued_organisation_en
                              ? i.name_of_issued_organisation_en
                              : i.name_of_issued_organisation_zh_tw
                            : i.name_of_issued_organisation_zh_tw
                            ? i.name_of_issued_organisation_zh_tw
                            : i.name_of_issued_organisation_en
                        }}
                      </div>
                      <div class="txt3">
                        {{
                          $i18n.locale == 'en'
                            ? i.name_of_qualification_en
                              ? i.name_of_qualification_en
                              : i.name_of_qualification_zh_tw
                            : i.name_of_qualification_zh_tw
                            ? i.name_of_qualification_zh_tw
                            : i.name_of_qualification_en
                        }}
                      </div>
                      <div class="txt3">{{ i.start_date }} - {{ i.end_date }}</div>
                      <div class="txt3">
                        {{ $i18n.locale == 'en' ? (i.description_en ? i.description_en : i.description_zh_tw) : i.description_zh_tw ? i.description_zh_tw : i.description_en }}
                      </div>
                    </div>
                  </div>
                  <div class="txt1">
                    {{ $t('CreateProfiles.placeholder9') }}
                  </div>
                  <div class="info-box">
                    <div class="card-left-info" v-for="(i, o) in info_data.Competitions" :key="o">
                      <div class="txt2">
                        {{
                          $i18n.locale == 'en'
                            ? i.name_of_competition_en
                              ? i.name_of_competition_en
                              : i.name_of_competition_zh_tw
                            : i.name_of_competition_zh_tw
                            ? i.name_of_competition_zh_tw
                            : i.name_of_competition_en
                        }}
                      </div>
                      <div class="txt3">
                        {{ $i18n.locale == 'en' ? (i.award_en ? i.award_en : i.award_zh_tw) : i.award_zh_tw ? i.award_zh_tw : i.award_en }}
                      </div>
                      <div class="txt3">{{ i.start_date }} - {{ i.end_date }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <el-button type="text" class="edit-btn" icon="el-icon-edit" @click="dialogFormVisible2 = true">{{ $t('CreateProfiles.edit') }}</el-button>
              <el-dialog :width="screenWidth > 480 ? '50%' : '98%'" :title="$t('CreateProfiles.editinfo')" :visible.sync="dialogFormVisible2">
                <el-form :model="form2" ref="form2" :rules="rules2">
                  <el-form-item :label="$t('CreateProfiles.infoTit2') + '(CN)'" :label-width="formLabelWidth">
                    <el-input type="textarea" v-model="form2.about_me_zh_tw" autocomplete="off"> </el-input>
                  </el-form-item>
                  <el-form-item :label="$t('CreateProfiles.infoTit2') + '(EN)'" :label-width="formLabelWidth">
                    <el-input type="textarea" v-model="form2.about_me_en" autocomplete="off"> </el-input>
                  </el-form-item>
                  <el-form-item :label="$t('CreateProfiles.fb_ig') + '(EN)'" :label-width="formLabelWidth" prop="fb_ig">
                    <el-input v-model="form2.fb_ig" autocomplete="off" :prop="form2.fb_ig"> </el-input>
                  </el-form-item>
                  <el-form-item :label="$t('CreateProfiles.infoTit3')" :label-width="formLabelWidth">
                    <el-select clearable :placeholder="$t('CreateProfiles.pleaseselect')" v-model="form2.is_public" style="width: 100%">
                      <el-option v-for="item in CoachingFeePublic" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                    </el-select>
                    <el-input v-if="form2.is_public" type="number" :placeholder="$t('CreateProfiles.Perhour')" v-model="form2.coaching_fee"> </el-input>
                  </el-form-item>
                  <div v-for="(QualificationCertificates, index) in form2.QualificationCertificates" :label="$t('CreateProfiles.infoTit4') + (index + 1)" :key="QualificationCertificates.key">
                    <!-- <el-form-item
                                        v-for="(
                                            QualificationCertificates, index
                                        ) in formQualif.QualificationCertificates"
                                        :label="
                                            $t('CreateProfiles.infoTit4') +
                                            (index + 1)
                                        "
                                        :key="QualificationCertificates.key"
                                        :prop="
                                            'QualificationCertificates.' +
                                            index +
                                            '.name_of_issued_organisation'
                                        "
                                    > -->
                    <div class="mb20">
                      <el-form-item :prop="'QualificationCertificates.' + index + '.name_of_issued_organisation_zh_tw'">
                        <el-input :placeholder="$t('CreateProfiles.placeholder1') + '(CN)'" v-model="QualificationCertificates.name_of_issued_organisation_zh_tw"> </el-input>
                      </el-form-item>
                    </div>
                    <div class="mb20">
                      <el-form-item :prop="'QualificationCertificates.' + index + '.name_of_issued_organisation_en'">
                        <el-input :placeholder="$t('CreateProfiles.placeholder1') + '(EN)'" v-model="QualificationCertificates.name_of_issued_organisation_en"> </el-input>
                      </el-form-item>
                    </div>
                    <div class="mb20">
                      <el-form-item :prop="'QualificationCertificates.' + index + '.name_of_qualification_zh_tw'">
                        <el-input :placeholder="$t('CreateProfiles.placeholder2') + '(CN)'" v-model="QualificationCertificates.name_of_qualification_zh_tw"> </el-input>
                      </el-form-item>
                    </div>
                    <div class="mb20">
                      <el-form-item :prop="'QualificationCertificates.' + index + '.name_of_qualification_en'">
                        <el-input :placeholder="$t('CreateProfiles.placeholder2') + '(EN)'" v-model="QualificationCertificates.name_of_qualification_en"> </el-input>
                      </el-form-item>
                    </div>
                    <div class="mb20">
                      <el-form-item :prop="'QualificationCertificates.' + index + '.start_date'">
                        <el-date-picker format="yyyy-MM" value-format="yyyy-MM" v-model="QualificationCertificates.start_date" type="month" :placeholder="$t('CreateProfiles.placeholder3')">
                        </el-date-picker>
                      </el-form-item>
                    </div>
                    <div class="mb20">
                      <el-form-item :prop="'QualificationCertificates.' + index + '.end_date'">
                        <el-date-picker format="yyyy-MM" value-format="yyyy-MM" v-model="QualificationCertificates.end_date" type="month" :placeholder="$t('CreateProfiles.placeholder33')">
                        </el-date-picker>
                      </el-form-item>
                    </div>
                    <div class="mb20">
                      <el-form-item :prop="'QualificationCertificates.' + index + '.description_zh_tw'">
                        <el-input :placeholder="$t('CreateProfiles.placeholder4') + '(CN)'" v-model="QualificationCertificates.description_zh_tw"></el-input>
                      </el-form-item>
                    </div>
                    <div class="mb20">
                      <el-form-item :prop="'QualificationCertificates.' + index + '.description_en'">
                        <el-input :placeholder="$t('CreateProfiles.placeholder4') + '(EN)'" v-model="QualificationCertificates.description_en"></el-input>
                      </el-form-item>
                    </div>
                    <div class="btn-container">
                      <el-button class="add-btn one" @click="addQ_C">{{ $t('CreateProfiles.add') + '\n' + $t('CreateProfiles.infoTit4') }}</el-button>
                      <div class="mb20 add-btn">
                        <el-button @click.prevent="removeQ_C()">{{ $t('CreateProfiles.remove') }}</el-button>
                      </div>
                    </div>
                  </div>
                  <div v-for="(Competitions, index) in form2.Competitions" :label="$t('CreateProfiles.placeholder9') + (index + 1)" :key="Competitions.key">
                    <div class="mb20">
                      <el-form-item :prop="'Competitions.' + index + '.name_of_competition_zh_tw'">
                        <el-input :placeholder="$t('CreateProfiles.placeholder10') + '(CN)'" v-model="Competitions.name_of_competition_zh_tw"></el-input>
                      </el-form-item>
                    </div>
                    <div class="mb20">
                      <el-form-item :prop="'Competitions.' + index + '.name_of_competition_en'">
                        <el-input :placeholder="$t('CreateProfiles.placeholder10') + '(EN)'" v-model="Competitions.name_of_competition_en"></el-input>
                      </el-form-item>
                    </div>
                    <div class="mb20">
                      <el-form-item :prop="'Competitions.' + index + '.award_zh_tw'">
                        <el-input :placeholder="$t('CreateProfiles.placeholder11') + '(CN)'" v-model="Competitions.award_zh_tw"></el-input>
                      </el-form-item>
                    </div>
                    <div class="mb20">
                      <el-form-item :prop="'Competitions.' + index + '.award_en'">
                        <el-input :placeholder="$t('CreateProfiles.placeholder11') + '(EN)'" v-model="Competitions.award_en"></el-input>
                      </el-form-item>
                    </div>
                    <div class="mb20">
                      <el-form-item :prop="'Competitions.' + index + '.start_date'">
                        <el-date-picker value-format="yyyy-MM" format="yyyy-MM" v-model="Competitions.start_date" type="month" :placeholder="$t('CreateProfiles.placeholder3')"> </el-date-picker>
                      </el-form-item>
                    </div>
                    <div class="mb20">
                      <el-form-item :prop="'Competitions.' + index + '.end_date'">
                        <el-date-picker format="yyyy-MM" value-format="yyyy-MM" v-model="Competitions.end_date" type="month" :placeholder="$t('CreateProfiles.placeholder33')"> </el-date-picker>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="btn-container">
                    <el-button class="add-btn one" @click="addCompetitions">{{ $t('CreateProfiles.add') + '\n' + $t('CreateProfiles.placeholder9') }} </el-button>
                    <div class="mb20 add-btn">
                      <el-button @click.prevent="removeCompetitions()">{{ $t('CreateProfiles.remove') }}</el-button>
                    </div>
                  </div>
                </el-form>
                <div slot="footer" class="dialog-footer">
                  <el-button @click="dialogFormVisible2 = false">{{ $t('CreateProfiles.cancel') }}</el-button>
                  <el-button type="primary" :loading="BtnLoading" @click="save('dialogFormVisible2', 'form2')"> {{ $t('CreateProfiles.save') }}</el-button>
                </div>
              </el-dialog>
            </div>
            <div class="card card-right">
              <div class="box">
                <div class="txt1">
                  {{ $t('CreateProfiles.infoTit5') }}
                </div>
              </div>
              <div class="box icon-box">
                <img class="icon-left" src="../assets/img/2-Icons/icons_profile-medal.svg" alt="" />
                <div class="icon-right">
                  <el-tooltip placement="bottom" popper-class="popper-width">
                    <div slot="content">
                      {{ $t('CreateProfiles.inspires_subtext1') }}<br />
                      {{ $t('CreateProfiles.inspires_subtext2') }}
                    </div>
                    <div class="txt3">
                      {{ $t('CreateProfiles.inspires') }} <br />
                      {{ $t('CreateProfiles.learning') }}
                    </div>
                  </el-tooltip>
                  <el-progress :text-inside="true" :stroke-width="16" :percentage="inspire_learn_record ? Number(((inspire_learn_record / 7) * 100).toFixed(1)) : 0" status="warning"></el-progress>
                </div>
              </div>
              <div class="box icon-box">
                <img class="icon-left" src="../assets/img/2-Icons/icons_safe_0408.png" alt="" />
                <div class="icon-right">
                  <el-tooltip placement="bottom" popper-class="popper-width">
                    <div slot="content">
                      {{ $t('CreateProfiles.safe_subtext1') }}<br />
                      {{ $t('CreateProfiles.safe_subtext2') }}
                    </div>
                    <div class="txt3">
                      {{ $t('CreateProfiles.safe') }} <br />
                      {{ $t('CreateProfiles.learning') }}
                    </div>
                  </el-tooltip>
                  <!-- something is wrong here -->
                  <el-progress
                    :text-inside="true"
                    :stroke-width="16"
                    :percentage="safeguarding_learn_record ? Number(((safeguarding_learn_record / 5) * 100).toFixed(2)) : 0"
                    status="warning"
                  ></el-progress>
                </div>
              </div>
              <div class="box icon-box">
                <img class="icon-left" src="../assets/img/2-Icons/icons_profile-trophy.svg" alt="" />
                <div class="icon-right">
                  <el-tooltip placement="bottom" popper-class="popper-width">
                    <div slot="content">
                      {{ $t('CreateProfiles.sharing_subtext1') }}<br />
                      {{ $t('CreateProfiles.sharing_subtext2') }}
                    </div>
                    <div class="txt3">
                      {{ $t('CreateProfiles.sharing') }}
                    </div>
                  </el-tooltip>
                  <el-progress :text-inside="true" :stroke-width="16" :percentage="event_record ? Number(((event_record / 3) * 100).toFixed(2)) : 0" status="warning"></el-progress>
                </div>
              </div>

              <div class="box">
                <div class="txt1">
                  {{ $t('CreateProfiles.infoTit6') }}
                  <span v-if="job_list">
                    {{ '(' + connect_num + ')' }}
                  </span>
                </div>
                <div class="icons">
                  <img class="icon-record" v-for="(i, o) in job_list" :key="o" :src="i" alt="" />
                </div>
              </div>
              <div class="box">
                <div class="txt1">
                  {{ $t('CreateProfiles.p1') }}
                </div>
                <div class="check-group">
                  <el-checkbox v-model="form1.certificate_of_education_path" :disabled="coachId">{{ $t('CreateProfiles.p2') }}</el-checkbox>
                  <el-checkbox v-model="form1.coach_license_path" :disabled="coachId">{{ $t('CreateProfiles.p3') }}</el-checkbox>
                  <el-checkbox v-model="form1.certificate_in_first_aid_path" :disabled="coachId">{{ $t('CreateProfiles.p4') }}</el-checkbox>
                  <el-checkbox v-model="form1.scrc_record_path" :disabled="coachId">{{ $t('CreateProfiles.p5') }}</el-checkbox>
                </div>
                <div class="info-btn">
                  <button class="now-btn" @click="goTo()">
                    {{ $t('CreateProfiles.p6') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="card card2">
            <div class="txt1">
              {{ $t('CreateProfiles.infoTit7') }}
            </div>
            <div class="experiences">
              <div class="experience" v-for="(i, o) in info_data.CoachingExperiences" :key="o">
                <div class="txt2" v-if="i.title">
                  {{ $i18n.locale == 'en' ? (i.title_en ? i.title_en : i.title_zh_tw) : i.title_zh_tw ? i.title_zh_tw : i.title_en }}
                </div>
                <div class="txt3">
                  {{
                    $i18n.locale == 'en'
                      ? i.name_of_organisation_en
                        ? i.name_of_organisation_en
                        : i.name_of_organisation_zh_tw
                      : i.name_of_organisation_zh_tw
                      ? i.name_of_organisation_zh_tw
                      : i.name_of_organisation_en
                  }}
                </div>
                <div class="txt3">{{ i.start_date }} - {{ i.end_date }}</div>
                <div class="txt3" v-if="i.location_zh_tw || i.location_en">
                  <span>
                    <img class="icons_location" src="../assets/img/2-Icons/icons_location.svg" alt="" />
                  </span>
                  <span>
                    <!-- Hong Kong  -->
                    {{ $i18n.locale == 'en' ? (i.location_en ? i.location_en : i.location_zh_tw) : i.location_zh_tw ? i.location_zh_tw : i.location_en }}
                  </span>
                </div>
                <div class="descriptions">
                  <div class="txt4" style="white-space: pre-wrap">
                    <!-- descriptions -->
                    {{ $i18n.locale == 'en' ? (i.description_1_en ? i.description_1_en : i.description_1_zh_tw) : i.description_1_zh_tw ? i.description_1_zh_tw : i.description_1_en }}
                  </div>
                </div>
              </div>
            </div>

            <el-button type="text" class="edit-btn" icon="el-icon-edit" @click="dialogFormVisible3 = true"> {{ $t('CreateProfiles.edit') }}</el-button>
            <el-dialog :width="screenWidth > 480 ? '50%' : '98%'" :title="$t('CreateProfiles.editinfo')" :visible.sync="dialogFormVisible3">
              <el-form :model="form3" ref="form3" :rules="rules3">
                <div v-for="(CoachingExperiences, index) in form3.CoachingExperiences" :label="$t('CreateProfiles.infoTit7') + (index + 1)" :key="CoachingExperiences.key">
                  <div class="mb20">
                    <el-form-item :prop="'CoachingExperiences.' + index + '.title_zh_tw'">
                      <el-input :placeholder="$t('CreateProfiles.placeholder8') + '(CN)'" v-model="CoachingExperiences.title_zh_tw"></el-input>
                    </el-form-item>
                  </div>
                  <div class="mb20">
                    <el-form-item :prop="'CoachingExperiences.' + index + '.title_en'">
                      <el-input :placeholder="$t('CreateProfiles.placeholder8') + '(EN)'" v-model="CoachingExperiences.title_en"></el-input>
                    </el-form-item>
                  </div>
                  <div class="mb20">
                    <el-form-item :prop="'CoachingExperiences.' + index + '.name_of_organisation_zh_tw'">
                      <el-input :placeholder="$t('CreateProfiles.placeholder5') + '(CN)'" v-model="CoachingExperiences.name_of_organisation_zh_tw"></el-input>
                    </el-form-item>
                  </div>
                  <div class="mb20">
                    <el-form-item :prop="'CoachingExperiences.' + index + '.name_of_organisation_en'">
                      <el-input :placeholder="$t('CreateProfiles.placeholder5') + '(EN)'" v-model="CoachingExperiences.name_of_organisation_en"></el-input>
                    </el-form-item>
                  </div>
                  <div class="mb20">
                    <el-form-item :prop="'CoachingExperiences.' + index + '.start_date'">
                      <el-date-picker v-model="CoachingExperiences.start_date" type="month" format="yyyy-MM " value-format="yyyy-MM" :placeholder="$t('CreateProfiles.date_start')"> </el-date-picker>
                    </el-form-item>
                  </div>
                  <div class="mb20">
                    <el-form-item :prop="'CoachingExperiences.' + index + '.end_date'">
                      <el-date-picker v-model="CoachingExperiences.end_date" type="month" format="yyyy-MM  " value-format="yyyy-MM" :placeholder="$t('CreateProfiles.date_end')"> </el-date-picker>
                    </el-form-item>
                  </div>
                  <div class="mb20">
                    <el-form-item :prop="'CoachingExperiences.' + index + '.location_zh_tw'">
                      <el-input :placeholder="$t('CreateProfiles.placeholder7') + '(CN)'" v-model="CoachingExperiences.location_zh_tw"></el-input>
                    </el-form-item>
                  </div>
                  <div class="mb20">
                    <el-form-item :prop="'CoachingExperiences.' + index + '.location_en'">
                      <el-input :placeholder="$t('CreateProfiles.placeholder7') + '(EN)'" v-model="CoachingExperiences.location_en"></el-input>
                    </el-form-item>
                  </div>
                  <div class="mb20">
                    <el-form-item :prop="'CoachingExperiences.' + index + '.description_1_zh_tw'">
                      <el-input :placeholder="$t('CreateProfiles.placeholder4') + '(CN)'" type="textarea" v-model="CoachingExperiences.description_1_zh_tw"> </el-input>
                    </el-form-item>
                  </div>
                  <div class="mb20">
                    <el-form-item :prop="'CoachingExperiences.' + index + '.description_1_en'">
                      <el-input :placeholder="$t('CreateProfiles.placeholder4') + '(EN)'" type="textarea" v-model="CoachingExperiences.description_1_en"> </el-input>
                    </el-form-item>
                  </div>
                  <el-button @click.prevent="removeExperiences(CoachingExperiences)">{{ $t('CreateProfiles.remove') }}</el-button>
                </div>
              </el-form>
              <div slot="footer" class="dialog-footer">
                <el-button @click="addExperiences">{{ $t('CreateProfiles.add') }}</el-button>
                <el-button @click="dialogFormVisible3 = false">{{ $t('CreateProfiles.cancel') }}</el-button>
                <el-button :loading="BtnLoading" type="primary" @click="save('dialogFormVisible3', 'form3')">{{ $t('CreateProfiles.save') }} </el-button>
              </div>
            </el-dialog>
          </div>
          <div class="card card3">
            <div class="txt1">
              {{ $t('CreateProfiles.infoTit8') }}
            </div>
            <div class="pics">
              <div class="pic-info" v-for="(i, o) in info_data.CoachMores" :key="o">
                <div class="pic">
                  <el-image class="pic-img" :src="i.cover_url ? i.cover_url : require('../assets/images/ihk-logo.jpeg')" :preview-src-list="srcList" alt=""> </el-image>
                  <div :class="delClass">
                    <i :class="elDelete" @click="deleteImg(o)"></i>
                  </div>
                </div>
                <div class="pic-txt">
                  <!-- {{ $t("CreateProfiles.title") }}: -->
                  {{ i.title_zh_tw }}
                </div>
                <div class="pic-txt">
                  {{ i.title_en }}
                </div>
                <div class="pic-txt" v-if="i.file_type == 'file'">
                  {{ $t('CreateProfiles.filepath') }}
                  <a :href="i.path_url" target="_blank" class="jump-to" rel="noreferrer">{{ $t('CreateProfiles.download') }}</a>
                </div>
                <div class="pic-txt" v-if="i.file_type == 'link'">
                  {{ $t('CreateProfiles.filelink') }}
                  <a :href="linkInit(i.link)" class="jump-to" target="_blank" rel="noreferrer">{{ $t('CreateProfiles.filelink') }}</a>
                </div>
              </div>
            </div>
            <div class="edit-btn plus-btn" @click="dialogFormVisible6 = true">
              <i class="el-icon-plus" />
            </div>
            <el-dialog :width="screenWidth > 480 ? '50%' : '98%'" :title="$t('CreateProfiles.upload')" :visible.sync="dialogFormVisible6">
              <el-form :model="form6">
                <el-form-item :label="$t('CreateProfiles.filetype')" :label-width="formLabelWidth">
                  <!-- <el-input v-model="more.title"></el-input> -->
                  <el-select :placeholder="$t('CreateProfiles.pleaseselect')" v-model="more.file_type" style="width: 100%">
                    <el-option v-for="item in fileType" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item :label="$t('CreateProfiles.cover')" :label-width="formLabelWidth">
                  <el-upload
                    ref="upload3"
                    action="#"
                    list-type="picture-card"
                    :limit="1"
                    :multiple="false"
                    :on-remove="handleRemove3"
                    :on-change="handleChange3"
                    :auto-upload="false"
                    :before-upload="beforeUpload3"
                    :http-request="uploadPhotos3"
                    :file-list="fileList3"
                  >
                    <i v-if="fileList3.length < 1" slot="default" class="el-icon-plus" />
                  </el-upload>
                </el-form-item>
                <el-form-item :label="$t('CreateProfiles.path')" v-if="more.file_type == 'file'" :label-width="formLabelWidth">
                  <el-upload
                    ref="upload4"
                    action="#"
                    :limit="1"
                    :multiple="false"
                    :on-remove="handleRemove4"
                    :on-change="handleChange4"
                    :auto-upload="false"
                    :before-upload="beforeUpload4"
                    :http-request="uploadPhotos4"
                    :file-list="fileList4"
                  >
                    <el-button size="small" type="primary">{{ $t('CreateProfiles.clickupload') }}</el-button>
                  </el-upload>
                </el-form-item>
                <el-form-item v-if="more.file_type == 'link'" :label="$t('CreateProfiles.link')" :label-width="formLabelWidth">
                  <el-input v-model="more.link"></el-input>
                </el-form-item>
                <el-form-item :label="$t('CreateProfiles.title')" :label-width="formLabelWidth">
                  <el-input v-model="more.title"></el-input>
                </el-form-item>
              </el-form>
              <div slot="footer" class="dialog-footer">
                <!-- <el-button @click="addMore">{{$t("CreateProfiles.add")}}</el-button> -->
                <el-button @click="dialogFormVisible6 = false">{{ $t('CreateProfiles.cancel') }}</el-button>
                <el-button :loading="BtnLoading" type="primary" @click="uploadCoachMore()">{{ $t('CreateProfiles.save') }}</el-button>
              </div>
            </el-dialog>
          </div>
          <div class="card card4">
            <div class="txt1">
              {{ $t('CreateProfiles.infoTit9') }}
            </div>
            <div class="tags">
              <div class="tag" v-for="(i, o) in info_data.Skills" :key="o">
                {{ i.name && Skills.find((one) => one.value == i.name) ? Skills.find((one) => one.value == i.name).label : i.name }}
              </div>
            </div>
            <el-button type="text" class="edit-btn" icon="el-icon-edit" @click="dialogFormVisible4 = true"> {{ $t('CreateProfiles.edit') }}</el-button>
            <el-dialog :width="screenWidth > 480 ? '50%' : '98%'" :title="$t('CreateProfiles.editinfo')" :visible.sync="dialogFormVisible4">
              <el-form :model="form4">
                <el-form-item v-for="(Skill, index) in form4.Skills" :label="$t('CreateProfiles.infoTit9') + (index + 1)" :key="Skill.key">
                  <div class="mb20">
                    <el-select :placeholder="$t('CreateProfiles.infoTit9')" v-model="Skill.name" style="width: 100%">
                      <el-option v-for="item in Skills" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                    </el-select>
                  </div>
                  <el-button @click.prevent="removeSkill(Skill)">{{ $t('CreateProfiles.remove') }}</el-button>
                </el-form-item>
              </el-form>
              <div slot="footer" class="dialog-footer">
                <el-button @click="addSkill" v-if="form4.Skills.length < 5">{{ $t('CreateProfiles.add') }}</el-button>
                <el-button @click="onClickCancel">{{ $t('CreateProfiles.cancel') }}</el-button>
                <el-button :loading="BtnLoading" type="primary" @click="save('dialogFormVisible4')">{{ $t('CreateProfiles.save') }}</el-button>
              </div>
            </el-dialog>
          </div>
          <div class="submit" v-if="!coachId">
            <el-button class="submit-btn" @click="saveInfo()">{{ $t('Jobs.submit') }}</el-button>
          </div>
        </div>
      </div>
    </div>
    <div class=""></div>
    <!-- terms and agreement -->
    <div class="fullbg" v-if="first_created">
      <div class="treaty">
        <div class="treaty-tit">
          {{ $t('CreateProfiles.treaty_tit') }}
        </div>
        <div class="treaty-line"></div>
        <div class="treaty-info">
          <div class="treaty-paragraph">
            {{ $t('CreateProfiles.treaty_ihk') }}
          </div>
          <div class="treaty-paragraph">
            {{ $t('CreateProfiles.paragraph1') }}
          </div>
          <div class="treaty-paragraph">
            {{ $t('CreateProfiles.paragraph2') }}
          </div>
          <div class="treaty-paragraph">
            {{ $t('CreateProfiles.paragraph3') }}
          </div>
          <div class="treaty-paragraph">
            {{ $t('CreateProfiles.paragraph4') }}
          </div>
          <div class="treaty-paragraph">
            {{ $t('CreateProfiles.paragraph5') }}
          </div>
          <div class="treaty-paragraph">
            {{ $t('CreateProfiles.paragraph6') }}
          </div>
          <div class="treaty-paragraph">
            {{ $t('CreateProfiles.paragraph7') }}
          </div>
          <div class="treaty-paragraph">
            {{ $t('CreateProfiles.paragraph8') }}
          </div>
        </div>
        <div class="treaty-line"></div>
        <div class="check">
          <label class="check-info">
            <span class="check-left">{{ $t('CreateProfiles.treaty1') }}</span>
            <span>
              <input class="check-right" type="checkbox" v-model="checkedArr[0]" />
            </span>
          </label>
          <label class="check-info">
            <span class="check-left">{{ $t('CreateProfiles.treaty2') }}</span>
            <span>
              <input class="check-right" type="checkbox" v-model="checkedArr[1]" />
            </span>
          </label>
          <label class="check-info">
            <span class="check-left">{{ $t('CreateProfiles.treaty3') }}</span>
            <span>
              <input class="check-right" type="checkbox" v-model="checkedArr[2]" />
            </span>
          </label>
          <div class="submit created" v-if="!coachId">
            <el-button class="submit-btn" @click="getClickConfirmed()">{{ $t('Jobs.submit') }}</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getUserCoachProfile, saveUserProfile, getProfileDetail, getSportTypes, unSavedProfiles, SavedProfiles, getMyConfirmedJobs, getConfirmProfile, getUnconfirmedJobs } from '@/api/coachProfile'
import { S3Api } from '@/api/fileUtility'
import { putJobInvite } from '@/api/member'

export default {
  name: 'createProfiles',
  data() {
    return {
      profile_id: '',
      styleLang: '',
      BtnLoading: false,
      screenWidth: window.document.body.clientWidth,
      dialogTableVisible: false,
      dialogFormVisible1: false,
      dialogFormVisible2: false,
      dialogFormVisible3: false,
      dialogFormVisible4: false,
      dialogFormVisible5: false,
      dialogFormVisible6: false,
      dialogFormVisible7: false,
      dialogFormVisible8: false,
      dialogConnect: false,
      picNum: null,
      file_info: null,
      info_path: null,
      coach_license_url: '',
      scrc_record_url: '',
      sportType: [],
      isSaved: false,
      first_created: false,
      bannerRadio: '',
      avatarRadio: '',
      fileType: [
        {
          value: 'file',
          label: this.$t('CreateProfiles.file'),
        },
        {
          value: 'image',
          label: this.$t('CreateProfiles.image'),
        },
        {
          value: 'link',
          label: this.$t('CreateProfiles.link'),
        },
      ],
      coachingTarget: [
        {
          value: 'Primary School',
          label: this.$t('CreateProfiles.coachingTarget1'),
        },
        {
          value: 'Secondary School',
          label: this.$t('CreateProfiles.coachingTarget2'),
        },
        {
          value: 'Parent-child',
          label: this.$t('CreateProfiles.coachingTarget3'),
        },
        {
          value: 'Elderly',
          label: this.$t('CreateProfiles.coachingTarget4'),
        },
        {
          value: 'Others',
          label: this.$t('CreateProfiles.coachingTarget5'),
        },
      ],
      forCoachingFee: [
        {
          value: 'Above market price',
          label: this.$t('CreateProfiles.forCoachingFee1'),
        },
        {
          value: 'Average market price',
          label: this.$t('CreateProfiles.forCoachingFee2'),
        },
        {
          value: 'Below market price',
          label: this.$t('CreateProfiles.forCoachingFee3'),
        },
        {
          value: 'Preferred not to say',
          label: this.$t('CreateProfiles.forCoachingFee4'),
        },
        {
          value: 'Volunteering',
          label: this.$t('CreateProfiles.forCoachingFee5'),
        },
      ],
      CoachingFeePublic: [
        {
          value: true,
          label: this.$t('CreateProfiles.public'),
        },
        {
          value: false,
          label: this.$t('CreateProfiles.unpublic'),
        },
      ],
      Skills: [
        {
          value: 'Motivate',
          label: this.$t('Skills.Motivate'),
        },
        {
          value: 'Passionate_about_sports',
          label: this.$t('Skills.Passionate_about_sports'),
        },
        {
          value: 'Casual',
          label: this.$t('Skills.Casual'),
        },
        {
          value: 'Creative_Thinking',
          label: this.$t('Skills.Creative_Thinking'),
        },
        {
          value: 'Passionate_about_teaching',
          label: this.$t('Skills.Passionate_about_teaching'),
        },
        {
          value: 'Flexibility',
          label: this.$t('Skills.Flexibility'),
        },
        {
          value: 'Confidence',
          label: this.$t('Skills.Confidence'),
        },
        {
          value: 'With_a_clearly',
          label: this.$t('Skills.With_a_clearly'),
        },
        {
          value: 'Goal_oriented',
          label: this.$t('Skills.Goal_oriented'),
        },
        {
          value: 'Knowledgeable',
          label: this.$t('Skills.Knowledgeable'),
        },
        {
          value: 'Careful',
          label: this.$t('Skills.Careful'),
        },
        {
          value: 'Respectful',
          label: this.$t('Skills.Respectful'),
        },
        {
          value: 'Patient',
          label: this.$t('Skills.Patient'),
        },
        {
          value: 'Chatty',
          label: this.$t('Skills.Chatty'),
        },
        {
          value: 'Approachable',
          label: this.$t('Skills.Approachable'),
        },
        {
          value: 'Fair',
          label: this.$t('Skills.Fair'),
        },
        {
          value: 'Logical',
          label: this.$t('Skills.Logical'),
        },
        {
          value: 'Supportive',
          label: this.$t('Skills.Supportive'),
        },
        {
          value: 'Persuasive',
          label: this.$t('Skills.Persuasive'),
        },
        {
          value: 'Calm',
          label: this.$t('Skills.Calm'),
        },
        {
          value: 'Curious',
          label: this.$t('Skills.Curious'),
        },
        {
          value: 'Principled',
          label: this.$t('Skills.Principled'),
        },
        {
          value: 'Systemic',
          label: this.$t('Skills.Systemic'),
        },
        {
          value: 'Adventurous',
          label: this.$t('Skills.Adventurous'),
        },
        {
          value: 'Positive',
          label: this.$t('Skills.Positive'),
        },
        {
          value: 'Optimistic',
          label: this.$t('Skills.Optimistic'),
        },
        {
          value: 'Leadership',
          label: this.$t('Skills.Leadership'),
        },
        {
          value: 'Inspiring',
          label: this.$t('Skills.Inspiring'),
        },
        {
          value: 'Professional',
          label: this.$t('Skills.Professional'),
        },
        {
          value: 'Good_listener',
          label: this.$t('Skills.Good_listener'),
        },
        {
          value: 'Reliable',
          label: this.$t('Skills.Reliable'),
        },
        {
          value: 'Attention_to_detail',
          label: this.$t('Skills.Attention_to_detail'),
        },
        {
          value: 'Self_discipline',
          label: this.$t('Skills.Self_discipline'),
        },
        {
          value: 'Good_communication_skills',
          label: this.$t('Skills.Good_communication_skills'),
        },
        {
          value: 'Harmonious',
          label: this.$t('Skills.Harmonious'),
        },
        {
          value: 'Friendly',
          label: this.$t('Skills.Friendly'),
        },
        {
          value: 'Perseverance',
          label: this.$t('Skills.Perseverance'),
        },
        {
          value: 'Clear_instructions_and_expressions',
          label: this.$t('Skills.Clear_instructions_and_expressions'),
        },
        {
          value: 'Accept_people_with_different_abilities',
          label: this.$t('Skills.Accept_people_with_different_abilities'),
        },
        {
          value: 'Rapid_response',
          label: this.$t('Skills.Rapid_response'),
        },
        {
          value: 'Kind',
          label: this.$t('Skills.Kind'),
        },
        {
          value: 'Tolerant',
          label: this.$t('Skills.Tolerant'),
        },
        {
          value: 'Strict_image',
          label: this.$t('Skills.Strict_image'),
        },
      ],
      form1: {
        name_zh_tw: '',
        name_en: '',
        title_zh_tw: '',
        title_en: '',
        main_sport: [],
        coach_target: [],
        cover: '',
        scrc_record_path: '',
        certificate_of_education_path: '',
        certificate_in_first_aid_path: '',
        coach_license_path: '',
      },
      rules1: {
        name_zh_tw: [
          {
            required: true, //是否必填
            message: 'Name cannot be empty', //规则
            trigger: 'blur', //何事件触发
          },
        ],
        name_en: [
          {
            required: true, //是否必填
            message: 'Name cannot be empty', //规则
            trigger: 'blur', //何事件触发
          },
        ],
        main_sport: [
          {
            type: 'array',
            required: true, //是否必填
            message: 'Main sport cannot be empty', //规则
            trigger: 'change',
          },
        ],
        coach_target: [
          {
            type: 'array',
            required: true, //是否必填
            message: 'Coach target cannot be empty', //规则
            trigger: 'change',
          },
        ],
      },
      learn_record: null,
      inspire_learn_record: null,
      safeguarding_learn_record: null,
      event_record: 0,
      form2: {
        about_me_zh_tw: '',
        about_me_en: '',
        coaching_fee: '',
        is_public: '',
        fb_ig: '',
        QualificationCertificates: [
          {
            name_of_issued_organisation_zh_tw: '',
            name_of_issued_organisation_en: '',
            name_of_qualification_zh_tw: '',
            name_of_qualification_en: '',
            start_date: '',
            end_date: '',
            description_zh_tw: '',
            description_en: '',
          },
        ],
        Competitions: [
          {
            name_of_competition_zh_tw: '',
            award_zh_tw: '',
            start_date: '',
            end_date: '',
            name_of_competition_en: '',
            award_en: '',
          },
        ],
      },
      rules2: {
        'QualificationCertificates.0.name_of_issued_organisation_zh_tw': [
          {
            required: true, //是否必填
            message: 'Name of issued organisation cannot be empty', //规则
            trigger: 'blur', //何事件触发
          },
        ],
        'QualificationCertificates.0.name_of_issued_organisation_en': [
          {
            required: true, //是否必填
            message: 'Name of issued organisation cannot be empty', //规则
            trigger: 'blur', //何事件触发
          },
        ],
        'QualificationCertificates.0.name_of_qualification_zh_tw': [
          {
            required: true, //是否必填
            message: 'Name of qualification cannot be empty', //规则
            trigger: 'blur', //何事件触发
          },
        ],
        'QualificationCertificates.0.name_of_qualification_en': [
          {
            required: true, //是否必填
            message: 'Name of qualification cannot be empty', //规则
            trigger: 'blur', //何事件触发
          },
        ],
        'QualificationCertificates.0.start_date': [
          {
            required: true, //是否必填
            message: 'Start date cannot be empty', //规则
            trigger: 'blur',
          },
        ],
        'QualificationCertificates.0.end_date': [
          {
            required: true, //是否必填
            message: 'End date cannot be empty', //规则
            trigger: 'blur',
          },
        ],
        'QualificationCertificates.0.description_zh_tw': [
          {
            required: true, //是否必填
            message: 'Description cannot be empty', //规则
            trigger: 'blur', //何事件触发
          },
        ],
        'QualificationCertificates.0.description_en': [
          {
            required: true, //是否必填
            message: 'Description cannot be empty', //规则
            trigger: 'blur', //何事件触发
          },
        ],
        'Competitions.0.name_of_competition_zh_tw': [
          {
            required: true, //是否必填
            message: 'Name of competition cannot be empty', //规则
            trigger: 'blur', //何事件触发
          },
        ],
        'Competitions.0.award_zh_tw': [
          {
            required: true, //是否必填
            message: 'Name of competition cannot be empty', //规则
            trigger: 'blur', //何事件触发
          },
        ],
        'Competitions.0.start_date': [
          {
            required: true, //是否必填
            message: 'Start date cannot be empty', //规则
            trigger: 'blur',
          },
        ],
        'Competitions.0.end_date': [
          {
            required: true, //是否必填
            message: 'End date cannot be empty', //规则
            trigger: 'blur',
          },
        ],
        'Competitions.0.name_of_competition_en': [
          {
            required: true, //是否必填
            message: 'Name of competition cannot be empty', //规则
            trigger: 'blur', //何事件触发
          },
        ],
        'Competitions.0.award_en': [
          {
            required: true, //是否必填
            message: 'Name of competition cannot be empty', //规则
            trigger: 'blur', //何事件触发
          },
        ],
      },
      form3: {
        CoachingExperiences: [
          {
            title_zh_tw: '',
            title_en: '',
            name_of_organisation_zh_tw: '',
            name_of_organisation_en: '',
            start_date: '',
            end_date: '',
            location_zh_tw: '',
            location_en: '',
            description_1_zh_tw: '',
            description_1_en: '',
          },
        ],
      },
      rules3: {
        'CoachingExperiences.0.title_zh_tw': [
          {
            required: true, //是否必填
            message: 'Name of issued organisation cannot be empty', //规则
            trigger: 'blur', //何事件触发
          },
        ],
        'CoachingExperiences.0.title_en': [
          {
            required: true, //是否必填
            message: 'Name of issued organisation cannot be empty', //规则
            trigger: 'blur', //何事件触发
          },
        ],
        'CoachingExperiences.0.name_of_organisation_zh_tw': [
          {
            required: true, //是否必填
            message: 'Name of qualification cannot be empty', //规则
            trigger: 'blur', //何事件触发
          },
        ],
        'CoachingExperiences.0.name_of_organisation_en': [
          {
            required: true, //是否必填
            message: 'Name of qualification cannot be empty', //规则
            trigger: 'blur', //何事件触发
          },
        ],
        'CoachingExperiences.0.start_date': [
          {
            required: true, //是否必填
            message: 'Start date cannot be empty', //规则
            trigger: 'blur',
          },
        ],
        'CoachingExperiences.0.end_date': [
          {
            required: true, //是否必填
            message: 'End date cannot be empty', //规则
            trigger: 'blur',
          },
        ],
        'CoachingExperiences.0.location_zh_tw': [
          {
            required: true, //是否必填
            message: 'Description cannot be empty', //规则
            trigger: 'blur', //何事件触发
          },
        ],
        'CoachingExperiences.0.location_en': [
          {
            required: true, //是否必填
            message: 'Description cannot be empty', //规则
            trigger: 'blur', //何事件触发
          },
        ],
        'CoachingExperiences.0.description_1_zh_tw': [
          {
            required: true, //是否必填
            message: 'Name of competition cannot be empty', //规则
            trigger: 'blur', //何事件触发
          },
        ],
        'CoachingExperiences.0.description_1_en': [
          {
            required: true, //是否必填
            message: 'Name of award cannot be empty', //规则
            trigger: 'blur', //何事件触发
          },
        ],
      },
      form4: {
        Skills: [
          {
            name: '',
          },
        ],
      },
      form5: {
        banner: '',
      },
      form6: {
        CoachMores: [],
      },
      // form7: { scrc_record_path: "" },
      // form8: { coach_license_path: "" },
      more: {
        file_type: '',
        link: '',
        title_en: '',
        title_zh_tw: '',
        cover: '',
        path: '',
      },
      imageUrl: '',
      file1: '',
      file2: '',
      file3: '',
      file4: '',
      file5: '',
      file6: '',
      fileList1: [],
      fileList2: [],
      fileList3: [],
      fileList4: [],
      submitLoading: false,
      isFileChange: false,
      isFileChange2: false,
      isFileChange3: false,
      isFileChange4: false,
      delClass: 'delete-img',
      elDelete: 'el-icon-delete',
      formLabelWidth: '120px',
      checkedArr: [false, false, false],
      isFull: true,
      info_data: {},
      info: {
        name: 'Thomas Chan',
        title: 'Title',
        star: 4,
        mainSport: ['Skill 1', 'Skill 2', 'Skill 3', 'Skill 4'],
      },
      userId: null,
      coachId: null,
      job_list: [],
      connect_num: 0,
      jobId: null,
      job_message: '',
      myJob: [{ index: '', event_name: '', id: null }],
      myUnconfirmedJobs: [],
      imagebanners: [
        {
          url: 'https://df7ocie544p6c.cloudfront.net/default/banner-01.png',
          filename: 'default/banner-01.png',
        },
        {
          url: 'https://df7ocie544p6c.cloudfront.net/default/banner-02.png',
          filename: 'default/banner-02.png',
        },

        {
          url: 'https://df7ocie544p6c.cloudfront.net/images/Banner_Sports_1-20220606.jpg',
          filename: 'images/Banner_Sports_1-20220606.jpg',
        },
        {
          url: 'https://df7ocie544p6c.cloudfront.net/images/Banner_Sports_2-20220606.jpg',
          filename: 'images/Banner_Sports_2-20220606.jpg',
        },
        {
          url: 'https://df7ocie544p6c.cloudfront.net/images/Banner_Sports_3-20220606.jpg',
          filename: 'images/Banner_Sports_3-20220606.jpg',
        },
        {
          url: 'https://df7ocie544p6c.cloudfront.net/images/Banner_Sports_4-20220606.jpg',
          filename: 'images/Banner_Sports_4-20220606.jpg',
        },
      ],
      avatarimages: [
        {
          url: 'https://df7ocie544p6c.cloudfront.net/images/avatar1-20220606.png',
          filename: 'images/avatar1-20220606.png',
        },
        {
          url: 'https://df7ocie544p6c.cloudfront.net/images/avatar2-20220606.png',
          filename: 'images/avatar2-20220606.png',
        },
        {
          url: 'https://df7ocie544p6c.cloudfront.net/images/avatar3-20220606.png',
          filename: 'images/avatar3-20220606.png',
        },
        {
          url: 'https://df7ocie544p6c.cloudfront.net/images/avatar4-20220606.png',
          filename: 'images/avatar4-20220606.png',
        },
      ],
    }
  },
  watch: {
    checkedArr: function () {
      if (this.checkedArr.findIndex((one) => one === false) == -1) {
        this.removeFull()
      }
    },
  },
  computed: {
    headBg() {
      let header = 'https://df7ocie544p6c.cloudfront.net/images/avatar1-20220606.png'
      let bg = this.info_data.cover_url ? `url(${this.info_data.cover_url})` : `url(${header})`
      return {
        backgroundImage: bg,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
      }
    },
    bannerBg() {
      let banner = 'https://df7ocie544p6c.cloudfront.net/images/Banner_Sports_4-20220606.jpg'
      let bg = this.info_data.banner_url ? `url(${this.info_data.banner_url})` : `url(${banner})`
      return {
        backgroundImage: bg,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
      }
    },
    srcList() {
      const src_list = []
      let i
      let arr = this.info_data.CoachMores
      for (i = 0; i < arr.length; i++) {
        src_list.push(arr[i].cover_url)
      }
      return src_list
    },
  },
  mounted() {
    this.userId = window.sessionStorage.getItem('user_id')
    this.coachId = this.GetId()
    this.getMyUnconfirmedJobs()
    this.getSports()
    console.log(this.coachId)
    if (this.coachId) {
      this.getMyJob()
      this.viewInit()
    } else {
      S3Api.refreshS3KeyAction().then((response) => {
        S3Api.saveS3KeyAction(response)
      })
      // document.body.style.overflow = "hidden";
      let removeFull = window.localStorage.getItem('removeFull')
      if (removeFull) {
        console.log(removeFull)
        this.removeFull()
      }
      this.getProfiles()
    }
  },
  methods: {
    getMyUnconfirmedJobs() {
      if (this.userId) {
        getUnconfirmedJobs(this.userId).then((res) => {
          this.myUnconfirmedJobs = res.data
        })
      }
    },
    changeBannerImg(url) {
      console.log({ url })
      this.form5.banner = url
    },
    changeAvatarImg(url) {
      this.form1.cover = url
    },
    getMyJob() {
      if (this.userId) {
        getMyConfirmedJobs().then((res) => {
          this.myJob = res.data
        })
      }
    },
    goTo() {
      window.open('#/coach-connect/how-verify')
    },
    viewInit() {
      this.removeFull()
      getProfileDetail(this.coachId, this.userId).then((res) => {
        this.info_data = res.data
        this.info_data.job_list ? (this.job_list = this.info_data.job_list) : []
        this.info_data.isSaved ? (this.isSaved = this.info_data.isSaved) : ''
        this.initData(res)
      })
      this.removeEditBtn()
    },
    saveCoach() {
      let id = this.coachId
      let flag = this.isSaved
      console.log(flag)
      if (this.userId) {
        if (flag) {
          unSavedProfiles(id).then((res) => {
            this.viewInit()
          })
        } else {
          SavedProfiles(id).then((res) => {
            this.viewInit()
          })
        }
      } else {
        this.$prompt.alert(this.$t('CoachProfiles.please3'), this.$t('CoachProfiles.welcome'), {
          confirmButtonText: this.$t('CoachProfiles.confirm'),
          confirmButtonClass: 'box-btn',
        })
      }
    },
    handleConnect() {
      let id = this.coachId
      if (this.userId) {
        this.dialogConnect = true
      } else {
        this.$prompt.alert(this.$t('CoachProfiles.please3'), this.$t('CoachProfiles.welcome'), {
          confirmButtonText: this.$t('CoachProfiles.confirm'),
          confirmButtonClass: 'box-btn',
        })
      }
    },
    confirmJob() {
      let data = {
        coach_id: this.coachId,
        job_id: this.jobId,
        message: this.job_message,
      }
      putJobInvite(data)
        .then((res) => {
          if (res.result == 'success') {
            this.$message({
              message: 'success',
              type: 'success',
            })
          }
          this.dialogConnect = false
        })
        .catch((err) => {
          console.log(err)
          this.dialogConnect = false
        })
    },
    getSports() {
      getSportTypes().then((res) => {
        this.sportType = res.data.data
      })
    },
    linkInit(link) {
      let newLink
      let reg = /(http:\/\/|https:\/\/)((\w|=|\?|\.|\/|&|-)+)/g
      if (link.match(reg)) {
        newLink = link
      } else {
        newLink = `https://${link}`
      }
      return newLink
    },
    uploadCoachMore() {
      if (this.more.file_type == 'file' && this.isFileChange4 && !this.isFileChange3) {
        this.$refs.upload4.submit()
      } else if (!this.isFileChange3 && !this.isFileChange4) {
        this.saveMore()
      } else if (this.isFileChange3) {
        this.uploadPic3()
      } else {
        this.saveMore()
      }
    },
    subBtn(id) {
      if (id == 2) {
        if (!this.info_data.scrc_record_url && !this.coachId) {
          this.dialogFormVisible5 = true
          this.picNum = 2
        } else {
          this.info_path = this.info_data.scrc_record_url
          this.dialogFormVisible7 = true
        }
      } else if (id == 3) {
        if (!this.info_data.coach_license_url && !this.coachId) {
          this.dialogFormVisible5 = true
          this.picNum = 3
        } else {
          this.info_path = this.info_data.coach_license_url
          this.dialogFormVisible7 = true
        }
      }
    },
    GetId() {
      return window.location.hash.split('=')[1]
    },
    removeFull() {
      document.body.style.overflow = 'scroll'
      this.isFull = false
      window.localStorage.setItem('removeFull', 'ture')
    },
    removeEditBtn() {
      let btn = document.getElementsByClassName('edit-btn')
      for (var i = 0; i < btn.length; i++) {
        btn[i].style.display = 'none'
      }
      this.delClass = ''
      this.elDelete = ''
      let ban = document.getElementsByClassName('banner-btn')
      for (var k = 0; k < ban.length; k++) {
        ban[k].style.display = 'none'
      }
    },
    getProfiles() {
      getUserCoachProfile().then((res) => {
        console.log(res)
        this.isSaved = res?.isSaved
        this.first_created = res.data.first_created
        this.profile_id = res.data.id
        this.initData(res)
      })
    },
    initData(res) {
      this.info_data = res.data
      this.job_list = this.info_data.job_list
      this.connect_num = this.info_data.connect_num
      this.form1.name_zh_tw = this.info_data.name_zh_tw
      this.form1.name_en = this.info_data.name_en
      this.form1.fb_ig = this.info_data.fb_ig
      this.form1.title_zh_tw = this.info_data.title_zh_tw
      this.form1.title_en = this.info_data.title_en
      this.form1.main_sport = this.info_data.main_sport
      this.form1.coach_target = this.info_data.coach_target
      this.form1.scrc_record_path = this.info_data.scrc_record_path
      this.form1.certificate_of_education_path = this.info_data.certificate_of_education_path
      this.form1.certificate_in_first_aid_path = this.info_data.certificate_in_first_aid_path
      this.form1.coach_license_path = this.info_data.coach_license_path
      this.form2.about_me_zh_tw = this.info_data.about_me_zh_tw
      this.form2.about_me_en = this.info_data.about_me_en
      this.form2.is_public = this.info_data.is_public
      this.form2.coaching_fee = this.info_data.coaching_fee
      this.learn_record = this.info_data.learn_record
      this.inspire_learn_record = this.info_data.inspire_learn_record
      this.safeguarding_learn_record = this.info_data.safeguarding_learn_record
      this.event_record = this.info_data.event_record
      if (this.info_data.QualificationCertificates.length > 0) {
        this.form2.QualificationCertificates = this.info_data.QualificationCertificates
      }
      if (this.info_data.Competitions.length > 0) {
        this.form2.Competitions = this.info_data.Competitions
      }
      if (this.info_data.CoachingExperiences.length > 0) {
        this.form3.CoachingExperiences = this.info_data.CoachingExperiences
      }
      if (this.info_data.Skills.length > 0) {
        this.info_data.Skills.map((one) => {
          delete one.id
          delete one.click
          delete one.createdAt
          delete one.profile_id
          delete one.updatedAt
        })
        this.form4.Skills = this.info_data.Skills
      }
      if (this.info_data.CoachMores.length > 0) {
        this.form6.CoachMores = this.info_data.CoachMores
      }
      this.coach_license_url = this.info_data.coach_license_url
      this.scrc_record_url = this.info_data.scrc_record_url
    },
    save(dialog, form) {
      if (form) {
        this.$refs[form].validate((valid) => {
          if (valid) {
            this.saveInfo(dialog)
          } else {
            console.log('error submit!!')
          }
        })
      } else {
        this.saveInfo(dialog)
      }
    },
    saveInfo(dialog) {
      this.BtnLoading = true
      let parmas = {
        ...this.form1,
        ...this.form2,
        ...this.form3,
        ...this.form4,
        ...this.form5,
        ...this.form6,
        // ...this.form7,
        // ...this.form8,
      }
      console.log(parmas)
      saveUserProfile(parmas)
        .then((res) => {
          this.BtnLoading = false
          if (dialog) {
            this[dialog] = false
          }
          console.log(this[dialog])
          this.initData(res)
          this.$message({
            message: this.$t('CreateProfiles.message'),
            type: 'success',
          })
        })
        .catch(() => {
          this.BtnLoading = false
          if (dialog) {
            this[dialog] = false
          }
          this.$message.error('error')
        })
    },
    saveMore() {
      this.more.cover = 'images/default.jpg'
      if (this.more.file_type == 'link') {
        this.more.path = this.more.link
      } else {
        if (!this.more.path) {
          this.more.path = this.more.cover
        }
      }
      this.form6.CoachMores.push(this.more)
      this.save('dialogFormVisible6')
      this.more = {
        file_type: '',
        link: '',
        title_en: '',
        title_zh_tw: '',
        cover: '',
        path: '',
      }
    },
    removeQ_C(item) {
      if (this.form2.QualificationCertificates.length != 1) {
        this.form2.QualificationCertificates.pop()
      }
    },
    removeCompetitions(item) {
      // console.log(this.form2.Competitions != 1)
      if (this.form2.Competitions.length != 1) {
        this.form2.Competitions.pop()
      }
    },
    addQ_C() {
      this.form2.QualificationCertificates.push({
        name_of_issued_organisation_zh_tw: '',
        name_of_issued_organisation_en: '',
        name_of_qualification_zh_tw: '',
        name_of_qualification_en: '',
        start_date: '',
        end_date: '',
        description_zh_tw: '',
        description_en: '',
      })
    },
    addCompetitions() {
      this.form2.Competitions.push({
        name_of_competition_zh_tw: '',
        award_zh_tw: '',
        start_date: '',
        end_date: '',
        description_zh_tw: '',
        name_of_competition_en: '',
        award_en: '',
        description_en: '',
      })
    },
    removeExperiences(item) {
      let index = this.form3.CoachingExperiences.indexOf(item)
      if (index !== -1) {
        this.form3.CoachingExperiences.splice(index, 1)
      }
    },
    addExperiences() {
      this.form3.CoachingExperiences.push({
        title_zh_tw: '',
        title_en: '',
        name_of_organisation_zh_tw: '',
        name_of_organisation_en: '',
        start_date: '',
        end_date: '',
        location_zh_tw: '',
        location_en: '',
        description_1_zh_tw: '',
        description_1_en: '',
      })
    },
    removeSkill(item) {
      let index = this.form4.Skills.indexOf(item)
      if (index !== -1) {
        this.form4.Skills.splice(index, 1)
      }
    },
    addSkill() {
      this.form4.Skills.push({
        name: '',
      })
    },
    handleRemove1(file) {
      this.fileList1.splice(this.fileList1.indexOf(file), 1)

      if (this.picNum == 1) {
        this.form5.banner = ''
        this.file1 = ''
      } else if (this.picNum == 2) {
        this.form7.scrc_record_path = ''
        this.file4 = ''
      } else if (this.picNum == 3) {
        this.form8.coach_license_path = ''
        this.file5 = ''
      }
    },
    handleChange1(file, list) {
      this.isFileChange = true
    },
    beforeUpload1(file) {
      if (this.picNum == 1) {
        this.file1 = file
      } else if (this.picNum == 2) {
        this.file4 = file
      } else if (this.picNum == 3) {
        this.file5 = file
      }
      this.file1 = file
      this.fileList1.push(file)
    },
    uploadPhotos1() {
      if (this.picNum == 1) {
        this.file_info = this.file1
      } else if (this.picNum == 2) {
        this.file_info = this.file4
      } else if (this.picNum == 3) {
        this.file_info = this.file5
      }
      S3Api.uploadPhoto(this.file_info)
        .then((res) => {
          if (this.picNum == 1) {
            this.form5.banner = res.Key
          } else if (this.picNum == 2) {
            this.form7.scrc_record_path = res.Key
          } else if (this.picNum == 3) {
            this.form8.coach_license_path = res.Key
          }
          this.fileList1 = []
          this.save('dialogFormVisible5')
        })
        .catch(() => {})
    },
    uploadPic1() {
      this.BtnLoading = true
      this.$refs.upload1.submit()
    },
    handleRemove2(file) {
      this.fileList2.splice(this.fileList2.indexOf(file), 1)
      this.file2 = ''
      this.form1.cover = ''
    },
    handleChange2(file, list) {
      this.isFileChange2 = true
    },
    beforeUpload2(file) {
      this.file2 = file
      this.fileList2.push(file)
    },
    uploadPhotos2() {
      S3Api.uploadPhoto(this.file2)
        .then((res) => {
          this.form1.cover = res.Key
          this.fileList2 = []
          console.log(res.Key)
          this.save('dialogFormVisible1')
        })
        .catch(() => {})
    },
    uploadPic2() {
      this.BtnLoading = true
      this.$refs.upload2.submit()
    },
    handleRemove3(file) {
      this.fileList3.splice(this.fileList3.indexOf(file), 1)
      this.file3 = ''
      this.more.cover = ''
    },
    handleChange3(file, list) {
      this.isFileChange3 = true
    },
    beforeUpload3(file) {
      this.file3 = file
      this.fileList3.push(file)
    },
    uploadPhotos3() {
      S3Api.uploadPhoto(this.file3)
        .then((res) => {
          this.more.cover = res.Key
          console.log(res.Key)
          if (this.more.file_type !== 'file') {
            if (this.more.file_type == 'link') {
              this.more.path = this.more.link
            } else if (this.more.file_type == 'image') {
              this.more.path = this.more.cover
            }
            this.form6.CoachMores.push(this.more)
            this.save('dialogFormVisible6')
            this.more = {
              file_type: '',
              link: '',
              title_en: '',
              title_zh_tw: '',
              cover: '',
              path: '',
            }
          } else if (this.more.file_type == 'file' && !this.isFileChange4) {
            this.more.path = this.more.cover
            this.form6.CoachMores.push(this.more)
            this.save('dialogFormVisible6')
            this.more = {
              file_type: '',
              link: '',
              title_en: '',
              title_zh_tw: '',
              cover: '',
              path: '',
            }
          }
          this.isFileChange3 = false
          this.fileList3 = []
        })
        .catch(() => {})
    },
    handleRemove4(file) {
      this.fileList4.splice(this.fileList4.indexOf(file), 1)
      this.file6 = ''
      this.more.path = ''
    },
    handleChange4(file, list) {
      this.isFileChange4 = true
    },
    beforeUpload4(file) {
      this.file6 = file
      this.fileList4.push(file)
    },
    uploadPhotos4() {
      this.BtnLoading = true
      S3Api.uploadPhoto(this.file6)
        .then((res) => {
          this.more.path = res.Key
          console.log(res.Key)
          if (!this.more.cover) {
            this.more.cover = 'images/default.jpg'
          }
          this.form6.CoachMores.push(this.more)
          this.save('dialogFormVisible6')
          this.more = {
            file_type: '',
            link: '',
            title_en: '',
            title_zh_tw: '',
            cover: '',
            path: '',
          }
          this.isFileChange4 = false
          this.fileList4 = []
        })
        .catch(() => {})
    },
    uploadPic3() {
      this.BtnLoading = true
      this.$refs.upload3.submit()
      if (this.more.file_type == 'file' && this.isFileChange4) {
        this.$refs.upload4.submit()
      }
    },
    deleteImg(index) {
      if (index !== -1) {
        this.form6.CoachMores.splice(index, 1)
      }
      this.save()
    },
    deleteFile() {
      if (this.picNum == 2) {
        this.form7.scrc_record_path = ''
      } else if (this.picNum == 3) {
        this.form8.coach_license_path = ''
      }
      this.save('dialogFormVisible7')
    },
    getClickConfirmed() {
      getConfirmProfile(this.profile_id).then(() => {
        this.first_created = false
      })
    },
    onClickCancel() {
      this.dialogFormVisible4 = false
      getUserCoachProfile().then((res) => {
        console.log(res)
        this.form4.Skills = []
        if (res.Skills) {
          this.form4.Skills = res.Skills
        } else {
          this.form4.Skills.push({
            name: '',
          })
        }

        this.initData(res)
      })
    },
  },
}
</script>
<style lang="scss" scoped>
// #createProfiles {
//     position: relative;
// }
.check-group {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-bottom: 30px;
}

::v-deep {
  .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #f15922;
    border-color: #f15922;
  }

  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #f15922;
  }
}

.now-btn {
  width: 100%;
  height: 65px;
  background: #f15922;
  color: #fff;
  border: 1px solid #f15922;
  border-radius: 4px;
}

.submit-btn {
  width: 295px;
  margin: 20px auto;
  height: 65px;
  background: #f15922;
  border: 1px solid #f15922;
  border-radius: 4px;
  color: #fff;
}

.created {
  display: flex;
  justify-content: center;
  margin-top: 29px;
}

.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}

.el-icon-delete {
  cursor: pointer;
}

.btn-container {
  display: flex;
  justify-content: center;
}

.el-button {
  height: 60px;
}

.add-btn {
  margin-top: 20px;
}

.one {
  margin-right: 10px;
}

.mb20 {
  margin-bottom: 20px;
}

.mr20 {
  margin-right: 20px;
}

.cropper-content {
  .cropper {
    width: auto;
    height: 300px;
  }
}

.jump-to {
  color: #fff;
  background: #f15922;
  border: 1px solid #f15922;
  border-radius: 6px;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  padding: 3px;
}

.pic-txt {
  width: 335px;
  height: 58px;
  font-size: 20px;
  text-align: left;
  font-weight: 400;
  line-height: 28px;
  color: #1d1d1d;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.banner-choose {
  width: 100%;
}

.image-radios {
  padding-top: 2%;

  span.el-radio-button__inner {
    padding: 2px;
  }
}

.fullbg {
  z-index: 1;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0px;
  top: 0px;
  background: rgba(29, 29, 29, 0.75);
  overflow-y: scroll;

  // opacity: 0.75;
  .treaty {
    display: flex;
    flex-direction: column;
    box-sizing: content-box;
    margin: 130px auto;
    width: 734px;
    height: 847px;
    background: #ffffff;
    padding: 45px;
    opacity: 1;
    text-align: left;

    .treaty-tit {
      font-size: 20px;
      font-weight: 400;
      line-height: 28px;
      color: #28327b;
    }

    .treaty-line {
      margin-top: 20px;
      width: 642px;
      border: 1px solid #d1d4eb;
    }

    .check {
      .check-info {
        justify-content: space-between;
        display: flex;
        align-items: center;
        line-height: 24px;
        margin: 10px 0;

        input[type='checkbox'] {
          width: 40px;
          height: 40px;
          background-color: #fff;
          // -webkit-appearance: none;
          border: 1px solid #d1d4eb;
          border-radius: 50%;
          outline: none;
        }

        input[type='checkbox']:checked {
          background: #d1d4eb;
        }

        input[type='checkbox']:checked {
          background: #d1d4eb;
        }

        .check-left {
          font-size: 18px;
          font-weight: 400;
          color: #1d1d1d;
        }
      }
    }

    .treaty-info {
      padding-right: 50px;
      // height: 600px;
      flex: 1;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      color: #1d1d1d;
      overflow: scroll;

      .treaty-paragraph {
        margin: 20px 0;
      }
    }
  }
}

.banner {
  width: 100%;
  position: relative;
  padding-top: 19px;

  .banner-bg {
    // background: url("../assets/img/1-Box-Images/images_seminar.png")
    // no-repeat 100%/100% !important ;
    width: 100%;
    height: 467px;
    border-radius: 8px 8px 0px 0px;
  }

  .banner-bg-shadow {
    width: 100%;
    height: 311px;
    position: absolute;
    bottom: 0;
    background: linear-gradient(180deg, rgba(255, 0, 0, 0) 0%, #808080 100%);
  }

  .banner-tit {
    position: absolute;
    top: 0;
    width: 823px;
    height: 138px;
    background: #28327b;
    color: #fff;
    font-size: 38px;
    font-weight: bold;
    line-height: 138px;
    text-align: center;
  }

  .banner-btn {
    width: 348px;
    height: 65px;
    border: 1px solid #ffffff;
    border-radius: 4px;
    font-size: 22px;
    font-weight: 600;
    color: #ffffff;
    // line-height: 65px;
    text-align: center;
    position: absolute;
    right: 224px;
    bottom: 109px;
  }

  .banner-btn:hover {
    cursor: pointer;
  }
}

.center-background {
  width: 100%;
  background: url('../assets/img/bg-blue.jpg');
}

.el-dialog {
  .el-button:hover {
    background: #fbf7f6;
    border: #f15922;
    color: #f15922;
  }

  .el-button--primary {
    background: #f15922;
    border: #fff;
  }

  .el-button--primary:hover {
    color: #fff;
    background: #f15922;
  }
}

.info {
  position: relative;
  top: -79px;
  width: 100%;

  .card {
    background: #ffffff;
    box-shadow: 0px 0px 20px #d1d4eb;
    border-radius: 8px;
    margin-bottom: 41px;
  }

  .card-group {
    display: flex;
    justify-content: space-between;

    // margin-bottom: 49px;
    .card-left {
      position: relative;
      box-sizing: border-box;
      width: 61%;
      min-height: 563px;
      padding: 51px 125px 51px 46px;
    }

    .card-right {
      box-sizing: border-box;
      padding: 48px;
      width: 35.7%;
      min-height: 563px;

      .icon-box {
        display: flex;

        .icon-left {
          width: 70px;
          height: 70px;
          background: #fbf7f6;
          border-radius: 50%;
        }

        .icon-right {
          padding: 9px 0 0 20px;
        }
      }

      .icons {
        display: flex;

        .icon-record {
          margin-right: 11px;
          width: 54px;
          height: 54px;
          background: rgba(0, 0, 0, 0);
          border: 3px solid #d1d4eb;
          border-radius: 50%;
        }
      }
    }
  }

  .card1 {
    position: relative;
    display: grid;
    grid-template-columns: 65% 35%;
    padding: 57px 46px 57px 101px;

    .card1-left {
      display: flex;
      text-align: left;
      align-items: center;

      .card1-info {
        display: grid;
        grid-template-columns: 40% 60%;
        align-items: center;
      }

      .head-portrait {
        width: 280px;
        height: 280px;
        background: rgba(0, 0, 0, 0);
        border: 3px solid #d1d4eb;
        border-radius: 50%;
      }

      .person-info {
        margin: 5px 0 20px 40px;

        .card1-name {
          font-size: 32px;
          font-weight: bold;
          line-height: 46px;
          color: #28327b;
          display: flex;
          position: relative;

          .icon-group {
            position: absolute;
            right: 50px;

            .icon {
              width: 34px;
              height: 34px;
              background: #fbf7f6;
              border-radius: 50%;
              margin: 0 5px;
            }
          }
        }

        .card1-tit {
          font-size: 24px;
          font-weight: 400;
          line-height: 36px;
          color: #898989;
        }

        .stars {
          text-align: left;
          margin-top: 6px;

          .star {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            margin: 0 5px;
          }
        }

        .card1-left-line {
          margin: 30px auto;
          width: 100%;
          height: 0px;
          border: 1px solid #d1d4eb;
        }
      }
    }

    .card1-line {
      width: 0px;
      margin-right: 50px;
      border: 1px solid #d1d4eb;
      height: 200px;
    }

    .card1-right {
      display: flex;
      text-align: left;
      padding: 80px 1% 80px 0;
      flex: 1;

      .button-wrapper {
        width: 100%;
        .info-btn {
          width: 100%;
          display: flex;
          justify-content: space-between;
        }
      }

      .sub-btn {
        color: #8f98dc;
        background: #fff;
        margin-top: 15px;
        width: 241px;
        height: 65px;
        border: 1px solid #8f98dc;
        opacity: 1;
        border-radius: 4px;
      }

      .has-btn {
        color: #f15922;
        background: #fff;
        margin-top: 15px;
        width: 241px;
        height: 65px;
        border: 1px solid #f15922;
        opacity: 1;
        border-radius: 4px;
      }

      .save-btn {
        width: 100%;
        height: 65px;
        background: #fbf7f6;
        border: 1px solid #f15922;
        border-radius: 4px;
        color: #f15922;
        margin: 20px 0;
      }

      .now-btn {
        width: 100%;
        height: 65px;
        background: #f15922;
        color: #fff;
        border: 1px solid #f15922;
        border-radius: 4px;
      }
    }
  }

  .card2 {
    box-sizing: border-box;
    padding: 35px 217px 33px 46px;
    position: relative;

    // height: 281px;
    .experiences {
      display: flex;
      flex-wrap: wrap;

      .experience {
        width: 50%;
        margin: 25px 0;
      }

      // justify-content: space-between;
      .icons_location {
        width: 15px;
        height: 18px;
        line-height: 28px;
        margin: 0 5px;
      }

      .descriptions {
        margin-top: 14px;
      }
    }
  }

  .card3 {
    // height: 460px;
    position: relative;
    padding: 46px 200px 46px 46px;

    .pics {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .pic-info {
        margin-top: 20px;

        // width: 33.3%;
        .pic {
          position: relative;
          width: 399px;
          height: 242px;
          // background: #d1d4eb;
          margin-bottom: 16px;

          .pic-img {
            width: 100%;
            height: 100%;
          }

          .delete-img {
            display: none;
          }

          &:hover {
            .delete-img {
              display: block;
              position: absolute;
              width: 100%;
              height: 40px;
              line-height: 40px;
              left: 0px;
              bottom: 0;
              background: rgba(59, 60, 61, 0.5);
              // box-sizing: content-box;
              z-index: 999;
              cursor: pointer;
              text-align: right;

              i {
                margin: 8px 10px 0 0;
                display: block;
                font-size: 24px;
                color: white;
              }
            }
          }
        }

        .pic-txt {
          width: 335px;
          height: 58px;
          font-size: 20px;
          text-align: left;
          font-weight: 400;
          line-height: 28px;
          color: #1d1d1d;
        }
      }
    }

    .pics:after {
      content: '';
      width: 399px;
    }
  }

  .card4 {
    position: relative;
    padding: 43px 46px;

    .tags {
      display: flex;
      margin-top: 23px;

      .tag {
        width: 207px;
        height: 45px;
        margin-left: 25px;
        line-height: 45px;
        color: #fff;
        text-align: center;
        background: #8f98dc;
        border-radius: 23px;
      }
    }
  }

  .edit-btn {
    display: block;
    position: absolute;
    top: 0;
    right: 20px;
    font-size: 22px;
    font-weight: 400;
    line-height: 28px;
    color: #8f98dc;
  }

  .plus-btn {
    top: 50%;
    font-size: 20px;
    line-height: 50px;
    width: 50px;
    height: 50px;
    background: #f8f8f8;
    border-radius: 50%;
  }

  .box {
    margin-bottom: 50px;

    .info-box {
      display: flex;
      flex-wrap: wrap;

      .card-left-info {
        width: 50%;
        margin-bottom: 30px;
      }
    }
  }

  .txt1 {
    margin-bottom: 15px;
    text-align: left;
    font-size: 22px;
    font-weight: bold;
    line-height: 28px;
    color: #28327b;
  }

  .txt2 {
    text-align: left;
    font-size: 20px;
    font-weight: 600;
    line-height: 36px;
    color: #8f98dc;
  }

  .txt3 {
    text-align: left;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    color: #1d1d1d;
  }

  .main-sport {
    margin-bottom: 10px;
    width: 6rem;
  }

  .mainsport {
    word-wrap: break-word;
    margin-right: 10px;
  }

  .txt4 {
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: #1d1d1d;
  }

  .txt5 {
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    color: #8f98dc;
  }

  .txt6 {
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #8f98dc;
  }

  .txt7 {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    color: #ffffff;
  }
}
</style>
<style lang="scss">
@media screen and (max-width: 480px) {
  #createProfiles {
    .card1 {
      display: block;
      padding: 50px;

      .card1-info {
        display: block;
      }

      .icon-group {
        position: static;
      }
    }

    .card-group {
      display: block;

      .card-right {
        width: 100%;
      }

      .card-left {
        width: 100%;
      }
    }
  }
}

.el-progress--line {
  margin-bottom: 15px;
  width: 200px;
}
</style>
